import React from 'react';
import Page from './Page';
import '../Responsive.css';

function Home() {
    return (
        <Page />
    )
}

export default Home;